.image-list-container {
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: white;
  z-index: 1;
}

.title-section {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.image-divider {
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 756px) {
  .list-item {
    padding-top: "1rem";
  }
}
