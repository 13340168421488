.footer-container {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  z-index: 1;
  background: white;
}

.about-section {
  font-size: 1rem;
  color: #282c34;
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.footer-divider {
  margin-left: 2rem;
  margin-right: 2rem;
}

.headshot {
  width: 150px;
  height: 150px;
  border-radius: 75px;
}

.headshotXS {
  width: 125px;
  height: 125px;
  border-radius: 63px;
}

.text {
  margin-left: 3rem;
}

@media screen and (max-width: 756px) {
  .text {
    margin-left: unset;
  }
  .footer-container {
    margin-top: unset;
  }
}
